import qs from 'qs';
import settings from '../settings.json';
import CookieUtils from '../lib/cookie-util';
import { trackSearchResultDisplay } from '../scripts/tracking/tracking';

/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL(path = '') {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || settings.apiUri}${path}`;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(path, urlParamsObject = {}, options = {}) {
  // Merge default and user options
  const populateDeep = urlParamsObject.populateDeep === true ? true : false;

  if (urlParamsObject.populateDeep) {
    delete urlParamsObject.populateDeep;
  }

  const mergedOptions = {
    method: options?.body ? 'POST' : 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${settings.apiAccessToken}`,
      "Strapi-Response-Format": "v4",
    },
    ...options,
  };

  if (mergedOptions.body) {
    mergedOptions.body = JSON.stringify(mergedOptions.body);
  }

  // Build request URL
  const queryString = mergedOptions.body
    ? qs.stringify({
      populate: '*',
    })
    : qs.stringify({
      ...{
        pagination: {
          limit: 25,
          start: 0,
        },
        status: urlParamsObject.filters?.slug ? 'draft' : 'published',
      },
      ...urlParamsObject,
    });
  const requestUrl = `${getStrapiURL(`/api${path}${queryString ? `?${queryString}` : ''}`)}${(populateDeep ? '&pLevel' : '')}`;

  if (settings.logFetches) {
    console.log(requestUrl);
  }
  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions).catch(() => {
    return null;
  });

  // Handle response
  if (!response?.ok) {
    console.error(response?.statusText);
    return null;
  }
  const data = await response.json();
  return data;
}

export function getUserSearchResultSelection(searchTerm = null, searchData = null, onSelectCallback = null) {
  if (localStorage && CookieUtils.cookiesAccepted()) {
    try {
      searchTerm = searchTerm?.toLowerCase();
      const searchSelections = localStorage.getItem('searchSelections');
      const storedSearchSelections = searchSelections ? JSON.parse(localStorage.getItem('searchSelections')) : [];

      if (!searchTerm || storedSearchSelections.length === 0) {
        return storedSearchSelections;
      } else {
        return storedSearchSelections
          .filter((d) => d.description.toLowerCase().includes(searchTerm) || d.title.toLowerCase().includes(searchTerm) || !searchTerm?.length)
          .map((d) => {
            return {
              hit: d,
              text: d.title,
              type: 'history',
              onClick: (e) => {
                if (searchData && onSelectCallback != null) {
                  onSelectCallback(e, d, searchData);
                }
              },
            };
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  return [];
}

export function removeSavedUserSearchResultSelection(searchHit) {
  const results = getUserSearchResultSelection();
  if (results.length > 0 && searchHit && results.find((d) => d.id === searchHit.id)) {
    localStorage.setItem('searchSelections', JSON.stringify(results.filter((d) => d.id !== searchHit.id)));
    document.activeElement.blur();
  }
}

export function saveUserSearchResultSelection(searchHit) {
  if (localStorage && searchHit && CookieUtils.cookiesAccepted()) {
    searchHit.type = 'history';
    const storedSearchSelections = getUserSearchResultSelection();
    if (!storedSearchSelections.find((d) => d.id === searchHit.id)) {
      storedSearchSelections.push(searchHit);
      localStorage.setItem('searchSelections', JSON.stringify(storedSearchSelections));
    }
  }
}

export async function fetchSearchAPI(searchTerm = '', onSelectCallback, resultLimit = 7, serverSideFetch = false, window = null) {
  if (searchTerm?.length > 2 || resultLimit > 7) {
    const result = await fetch(`${settings.meilisearch.host}/indexes/article/search`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${settings.meilisearch.apiKey}`,
      }),
      body: JSON.stringify({
        q: searchTerm,
        limit: resultLimit,
        attributesToRetrieve: ['id', 'slug', 'title', 'synonyms', 'description', 'pageImpressionCount'],
        sort: ['title:desc', 'description:desc', 'synonyms:desc', 'blockContent.content:desc', 'pageImpressionCount:desc'],
        attributesToSearchOn: ['title', 'description', 'synonyms', 'blockContent.content'],
        showRankingScore: true,
      }),
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Search unavailable');
        }
      })
      .then((data) => {
        /*if (window) {
          trackSearchResultDisplay(window, data.query, data.estimatedTotalHits);
        }*/

        if (data.hits?.length > 0) {
          let result = [
            ...(serverSideFetch ? [] : getUserSearchResultSelection(searchTerm, data, onSelectCallback)),
            ...data.hits.map((r) => {
              return {
                hit: r,
                text: r.title,
                type: 'suggestion',
                onClick: (e) => {
                  onSelectCallback(e, r, data);
                },
              };
            }),
          ];

          return result;
        } else {
          return [];
        }
      })
      .catch((e) => {
        console.log(e);
        return [];
      });

    return result;
  }

  return new Promise((resolve) => {
    resolve([]);
  });
}
