import { FooterContent, FooterLogo } from '../../assets/css/style';
import { Row } from '../../components/styleguide-remapped/styleguide-remapped';
import { SvgWirSindDeins } from '../../assets/logos/wirSindDeins';
import Link from 'next/link';
import { useContext } from 'react';
import { GlobalContext } from '../../pages/_app';
import { uid } from 'react-uid';
import Image from 'next/image';
import React from 'react';

const Footer = ({}) => {
  const { footerLinks } = useContext(GlobalContext);

  const checkReloadContact = (linkPath) => {
    if(window.location.href.includes(linkPath)){
      window.location.reload();
    }
  };

  return (
    <FooterContent>
      <Row>
        <div className="bg-footer-image-wrapper">
          <Image src="/static/images/footer.png" quality="100" alt="ARD Helpdesk - Footer Logo" fill style={{ objectFit: 'cover' }} />
        </div>
        <div>
          {footerLinks?.map((linkObj, index) => {
            return (
              <Link href={linkObj.link} onClick={() => checkReloadContact(linkObj.link)} target={linkObj.targetBlank ? '_blank' : '_self'} key={uid(linkObj) + index}>
                {linkObj.text}
              </Link>
            );
          })}
        </div>
        <div>
          <FooterLogo>
            <SvgWirSindDeins />
          </FooterLogo>
        </div>
      </Row>
    </FooterContent>
  );
};

export default Footer;
